import { mapGetters } from 'vuex';

export const DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER = [
  { name: 'typebot_bots' },
  // { name: 'flowise_bots' },
  { name: 'conversation_actions' },
  { name: 'macros' },
  { name: 'automation_externals' },
  { name: 'conversation_info' },
  { name: 'contact_attributes' },
  { name: 'previous_conversation' },
  { name: 'conversation_participants' },
];
export const DEFAULT_CONTACT_SIDEBAR_ITEMS_ORDER = [
  { name: 'contact_attributes' },
  { name: 'contact_labels' },
  { name: 'previous_conversation' },
];

const slugifyChannel = name =>
  name.toLowerCase().replace(' ', '_').replace('-', '_').replace('::', '_');

export const isEditorHotKeyEnabled = (uiSettings, key) => {
  const {
    editor_message_key: editorMessageKey,
    enter_to_send_enabled: enterToSendEnabled,
  } = uiSettings || {};
  if (!editorMessageKey) {
    if (enterToSendEnabled) {
      return key === 'enter';
    }
    return key === 'cmd_enter';
  }
  return editorMessageKey === key;
};

export default {
  computed: {
    ...mapGetters({
      uiSettings: 'getUISettings',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    conversationSidebarItemsOrder() {
      const { conversation_sidebar_items_order: itemsOrder } = this.uiSettings;
      if (itemsOrder) {
        // If the sidebar order doesn't have the new elements, then add them to the list.
        DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER.forEach(item => {
          if (!itemsOrder.find(i => i.name === item.name)) {
            if (
              item.name === 'typebot_bots' &&
              this.isFeatureEnabledonAccount(this.accountId, 'typebot_bots')
            ) {
              itemsOrder.push(item);
            } else if (
              item.name === 'flowise_bots' &&
              this.isFeatureEnabledonAccount(this.accountId, 'flowise_bots')
            ) {
              itemsOrder.push(item);
            } else if (
              item.name !== 'typebot_bots' &&
              item.name !== 'flowise_bots'
            ) {
              itemsOrder.push(item);
            }
          }
        });
        return itemsOrder;
      }
      // If the sidebar order is not set, use the default order.
      if (!itemsOrder) {
        return DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER.filter(
          item =>
            !['typebot_bots', 'flowise_bots'].includes(item.name) ||
            (item.name === 'typebot_bots' &&
              this.isFeatureEnabledonAccount(this.accountId, 'typebot_bots')) ||
            (item.name === 'flowise_bots' &&
              this.isFeatureEnabledonAccount(this.accountId, 'flowise_bots'))
        );
      }
      // If the sidebar order doesn't have the new elements, then add them to the list.
      DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER.forEach(item => {
        if (!itemsOrder.find(i => i.name === item.name)) {
          itemsOrder.push(item);
        }
      });
      return itemsOrder;
    },
    contactSidebarItemsOrder() {
      const { contact_sidebar_items_order: itemsOrder } = this.uiSettings;
      return itemsOrder || DEFAULT_CONTACT_SIDEBAR_ITEMS_ORDER;
    },
  },
  methods: {
    updateUISettings(uiSettings = {}) {
      this.$store.dispatch('updateUISettings', {
        uiSettings: {
          ...this.uiSettings,
          ...uiSettings,
        },
      });
    },
    isContactSidebarItemOpen(key) {
      const { [key]: isOpen } = this.uiSettings;
      return !!isOpen;
    },
    toggleSidebarUIState(key) {
      this.updateUISettings({ [key]: !this.isContactSidebarItemOpen(key) });
    },
    setSignatureFlagForInbox(channelType, value) {
      channelType = slugifyChannel(channelType);
      this.updateUISettings({
        [`${channelType}_signature_enabled`]: value,
      });
    },
    fetchSignatureFlagFromUiSettings(channelType) {
      if (!channelType) return false;

      channelType = slugifyChannel(channelType);
      return this.uiSettings[`${channelType}_signature_enabled`];
    },
  },
};
