const Flowise = () => import('./Index.vue');
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/flowise-bots'),
      name: 'flowise_bots',
      roles: ['administrator'],
      component: Flowise,
    },
  ],
};
